export function backendDateSerialize(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}T23:59:59`;
}

export function backendDateDeserialize(date: string): Date {
  return new Date(date);
}
